var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"news",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"news__header"},[_c('h3',{staticClass:"news__header_title"},[_vm._v(_vm._s(_vm.news.id ? "Edit" : "Add")+" News")]),_c('div',{staticClass:"news__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete"},on:{"click":function($event){return _vm.$emit('removeItem', _vm.news)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid || _vm.news.header_image === '' || !_vm.changed},on:{"click":_vm.saveNews}})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("News Information")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Name","required":true,"validateError":errors[0] || _vm.serverError.title,"element":_vm.news.title},on:{"input":function($event){_vm.news.title = $event}},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Author","required":true,"validateError":errors[0],"element":_vm.news.author},on:{"input":function($event){_vm.news.author = $event}},model:{value:(_vm.news.author),callback:function ($$v) {_vm.$set(_vm.news, "author", $$v)},expression:"news.author"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"placeholder":"Date Published","time":false,"required":true,"endDate":_vm.today,"validateError":errors[0],"value":_vm.news.date_published},on:{"input":function($event){_vm.news.date_published = $event}},model:{value:(_vm.news.date_published),callback:function ($$v) {_vm.$set(_vm.news, "date_published", $$v)},expression:"news.date_published"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row form__row--align-start"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseSelect',{attrs:{"placeholder":"Status","type":"status","selected":_vm.news.status,"items":[
              { id: 0, title: 'Draft' },
              { id: 1, title: 'Published' } ]},on:{"input":function($event){_vm.news.status = $event.id}},model:{value:(_vm.news.status),callback:function ($$v) {_vm.$set(_vm.news, "status", $$v)},expression:"news.status"}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, path: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Path","required":true,"validateError":errors[0] || _vm.serverError.slug,"element":_vm.news.slug},on:{"input":function($event){_vm.news.slug = $event}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',{staticClass:"form-control__simple-text"},[_vm._v("Example: "+_vm._s((_vm.url + "/news/" + (_vm.news.slug || "path"))))])]},proxy:true}],null,true),model:{value:(_vm.news.slug),callback:function ($$v) {_vm.$set(_vm.news, "slug", $$v)},expression:"news.slug"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("News Details")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('Editor',{attrs:{"placeholder":"Text Editor","value":_vm.news.details},on:{"input":function($event){_vm.news.details = $event}},model:{value:(_vm.news.details),callback:function ($$v) {_vm.$set(_vm.news, "details", $$v)},expression:"news.details"}})],1)]),_c('div',{staticClass:"form__row"},[_c('MultipleAutocompleteInput',{attrs:{"id":"autocomplete-tag","focusOpen":true,"loading":_vm.tagsLoading,"items":_vm.news.tags,"matches":_vm.matches,"placeholder":"Tags"},on:{"addItem":_vm.addTag,"input":function($event){_vm.news.tags = $event},"searchMatches":_vm.apiGetMatchedTags},model:{value:(_vm.news.tags),callback:function ($$v) {_vm.$set(_vm.news, "tags", $$v)},expression:"news.tags"}})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button Text","validateError":errors[0],"element":_vm.news.button_text},on:{"input":function($event){_vm.news.button_text = $event}},model:{value:(_vm.news.button_text),callback:function ($$v) {_vm.$set(_vm.news, "button_text", $$v)},expression:"news.button_text"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button URL","validateError":errors[0],"element":_vm.news.button_url},on:{"input":function($event){_vm.news.button_url = $event}},model:{value:(_vm.news.button_url),callback:function ($$v) {_vm.$set(_vm.news, "button_url", $$v)},expression:"news.button_url"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Images")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Search Image")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"search","size":"15","image":_vm.news.search_image,"v-model":_vm.news.search_image},on:{"input":function($event){_vm.news.search_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.news.search_image = '';}}})],1),_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Header Image "),_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")])]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"header","size":"15","image":_vm.news.header_image,"v-model":_vm.news.header_image},on:{"input":function($event){_vm.news.header_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.news.header_image = '';}}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }